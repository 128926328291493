import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AutocompleteSelector from './AutocompleteSelector';
import CardsSelector from './CardsSelector';
import CheckboxesSelector from './CheckboxesSelector';

const OptionsSelector = ({ id, onChange, options, placeholder, type, value }) => {
  const { t } = useTranslation('general');
  const fixedOptions = options.map((option) => ({
    ...option,
    name: option.name === 'others' ? t('Others') : option.name,
  }));

  if (type === 'autocomplete') {
    return (
      <AutocompleteSelector
        id={id}
        options={fixedOptions}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    );
  }

  if (type === 'card') {
    return <CardsSelector options={fixedOptions} onChange={onChange} value={value} />;
  }

  if (type === 'checkbox') {
    return <CheckboxesSelector options={fixedOptions} onChange={onChange} value={value} />;
  }

  return null;
};

OptionsSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['autocomplete', 'card', 'checkbox']).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

OptionsSelector.defaultProps = {
  onChange: () => {},
  value: [],
};

export default OptionsSelector;
