import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const RearAxlesCapacityFilter = ({ type }) => {
  const {
    data, prevData, params, updateParams,
  } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.rearAxlesCapacities || prevData?.rearAxlesCapacities || [];

  const onChange = (value) => {
    updateParams({
      rearAxles: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="reat-axles-capacity-filter"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.rearAxles}
    />
  );
};

RearAxlesCapacityFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default RearAxlesCapacityFilter;
