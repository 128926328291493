import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const VehicleTypeFilter = ({ type }) => {
  const {
    data, prevData, params, updateParams,
  } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.vehicleTypes || prevData?.vehicleTypes || [];

  const onChange = (value) => {
    updateParams({
      vehicles: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="vehicle-type-filter"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.vehicles}
    />
  );
};

VehicleTypeFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default VehicleTypeFilter;
