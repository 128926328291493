import { useQuery } from '@apollo/client';
import getFiltersInfoQuery from '../queries/getFiltersInfoQuery';
import useSearchFiltersParams from './useSearchFiltersParams';

export default function useGetFiltersInfo() {
  const { params, updateParams } = useSearchFiltersParams();

  const { data, error, loading, previousData } = useQuery(getFiltersInfoQuery, {
    variables: params,
  });

  return {
    data: data?.getFiltersInfo,
    prevData: previousData?.getFiltersInfo,
    error,
    loading,
    params,
    updateParams,
  };
}
