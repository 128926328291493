import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const VehicleStateFilter = ({ type }) => {
  const { data, prevData, params, updateParams } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.states || prevData?.states || [];

  const onChange = (value) => {
    updateParams({
      states: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="state-filter"
      onChange={onChange}
      options={options}
      placeholder={t('State')}
      type={type}
      value={params.states}
    />
  );
};

VehicleStateFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default VehicleStateFilter;
