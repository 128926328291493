import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  textShadow: {
    textShadow: '1px 1px 1px rgb(0 0 0 / 50%)',
  },
});

const CompactCardItem = ({ backgroundImg, backgroundImgAlt, children }) => {
  const styles = useStyles();

  return (
    <div className="cursor-pointer relative h-64 w-full">
      <img
        className="absolute w-full h-full object-cover"
        src={backgroundImg}
        alt={backgroundImgAlt}
      />
      <div className="absolute w-full h-full flex flex-col justify-end">
        <div className={`p-2 text-white bg-black bg-opacity-25 ${styles.textShadow}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

CompactCardItem.propTypes = {
  backgroundImg: PropTypes.string,
  backgroundImgAlt: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CompactCardItem.defaultProps = {
  backgroundImg: '',
  backgroundImgAlt: '',
};

export default CompactCardItem;
