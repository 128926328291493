import PropTypes from 'prop-types';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import RangeSelector from '../ui/RangeSelector';
import formatNumber from '../utils/formatNumber';
import getLimits from '../utils/getLimits';

const KmsFilter = ({ type }) => {
  const { loading, params, prevData, data, updateParams } = useGetFiltersInfo();

  const onChange = ([min, max]) => {
    const limits = getLimits(data.kmsRanges);
    const allSelected = min === limits.min && max === limits.max;

    updateParams({
      minKms: allSelected ? null : min,
      maxKms: allSelected ? null : max,
      page: 1,
    });
  };

  return (
    <RangeSelector
      beginTag={(value) => `${formatNumber(value)} kms`}
      endTag={(value) => `${formatNumber(value)} kms`}
      onChange={onChange}
      ranges={data?.kmsRanges || prevData?.kmsRanges}
      type={type}
      value={loading ? null : [params.minKms, params.maxKms]}
      className="h-32"
    />
  );
};

KmsFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default KmsFilter;
