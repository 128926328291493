import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import useSearchFiltersParams from '../hooks/useSearchFiltersParams';
import formatNumber from '../utils/formatNumber';

const VehicleFilterChips = ({ className }) => {
  const { t } = useTranslation('search');
  const { selectedFilters: filters, updateParams } = useSearchFiltersParams();

  const chips = [];

  if (filters.locations) {
    chips.push(
      ...filters.locations.map((filter) => [
        `location ${filter}`,
        <Chip label={filter} onDelete={() => updateParams({ search: null })} />,
      ]),
    );
  }

  if (filters.search) {
    chips.push([
      'search',
      <Chip label={`"${filters.search}"`} onDelete={() => updateParams({ search: null })} />,
    ]);
  }

  if (filters.minPrice && filters.maxPrice) {
    chips.push([
      'price',
      <Chip
        label={t('Ranges', {
          context: 'price',
          from: formatNumber(filters.minPrice),
          to: formatNumber(filters.maxPrice),
        })}
        onDelete={() => updateParams({ minPrice: null, maxPrice: null })}
      />,
    ]);
  }

  if (filters.minKms && filters.maxKms) {
    chips.push([
      'kms',
      <Chip
        label={t('Ranges', {
          context: 'kms',
          from: formatNumber(filters.minKms),
          to: formatNumber(filters.maxKms),
        })}
        onDelete={() => updateParams({ minKms: null, maxKms: null })}
      />,
    ]);
  }

  if (filters.minEngineUsage && filters.maxEngineUsage) {
    chips.push([
      'usage',
      <Chip
        label={t('Ranges', {
          context: 'usage',
          from: formatNumber(filters.minEngineUsage),
          to: formatNumber(filters.maxEngineUsage),
        })}
        onDelete={() => updateParams({ minEngineUsage: null, maxEngineUsage: null })}
      />,
    ]);
  }

  if (filters.brands) {
    chips.push(
      ...filters.brands.map((brand) => [
        `brand ${brand}`,
        <Chip
          label={brand}
          onDelete={() => updateParams({ brands: filters.brands.filter((item) => item !== brand) })}
        />,
      ]),
    );
  }

  if (filters.models) {
    chips.push(
      ...filters.models.map((model) => [
        `model ${model}`,
        <Chip
          label={model}
          onDelete={() => updateParams({ models: filters.models.filter((item) => item !== model) })}
        />,
      ]),
    );
  }

  if (filters.cabs) {
    chips.push(...filters.cabs.map((cab) => [
      `cab ${cab}`,
      <Chip
        label={cab}
        onDelete={() => updateParams({ cabs: filters.cabs.filter((item) => item !== cab) })}
      />,
    ]));
  }

  if (filters.classes) {
    chips.push(...filters.classes.map((classValue) => [
      `class ${classValue}`,
      <Chip
        label={classValue}
        onDelete={() => {
          updateParams({ classes: filters.classes.filter((item) => item !== classValue) });
        }}
      />,
    ]));
  }

  if (filters.colors) {
    chips.push(
      ...filters.colors.map((color) => [
        `color ${color}`,
        <Chip
          label={color}
          onDelete={() => updateParams({ colors: filters.colors.filter((item) => item !== color) })}
        />,
      ]),
    );
  }

  if (filters.fifthWheels) {
    chips.push(...filters.fifthWheels.map((fWheel) => [
      `fw ${fWheel}`,
      <Chip
        label={fWheel}
        onDelete={() => {
          updateParams({ fifthWheels: filters.fifthWheels.filter((item) => item !== fWheel) });
        }}
      />,
    ]));
  }

  if (filters.fuels) {
    chips.push(...filters.fuels.map((fuel) => [
      `fuel ${fuel}`,
      <Chip
        label={fuel}
        onDelete={() => updateParams({ fuels: filters.fuels.filter((item) => item !== fuel) })}
      />,
    ]));
  }

  if (filters.states) {
    chips.push(
      ...filters.states.map((state) => [
        `state ${state}`,
        <Chip
          label={state}
          onDelete={() => updateParams({ states: filters.states.filter((item) => item !== state) })}
        />,
      ]),
    );
  }

  if (filters.transmissions) {
    chips.push(
      ...filters.transmissions.map((transmission) => [
        `transmission ${transmission}`,
        <Chip
          label={transmission}
          onDelete={() =>
            updateParams({
              transmissions: filters.transmissions.filter((item) => item !== transmission),
            })
          }
        />,
      ]),
    );
  }

  if (filters.years) {
    chips.push(
      ...filters.years.map((year) => [
        `year ${year}`,
        <Chip
          label={year}
          onDelete={() => updateParams({ years: filters.years.filter((item) => item !== year) })}
        />,
      ]),
    );
  }

  if (filters.bodyTypes) {
    chips.push(
      ...filters.bodyTypes.map((bodyType) => [
        `bt ${bodyType}`,
        <Chip
          label={bodyType}
          onDelete={() =>
            updateParams({
              bodyTypes: filters.bodyTypes.filter((item) => item !== bodyType),
            })
          }
        />,
      ]),
    );
  }

  if (filters.motors) {
    chips.push(...filters.motors.map((motor) => [
      `motor ${motor}`,
      <Chip
        label={motor}
        onDelete={() => updateParams({ motors: filters.motors.filter((item) => item !== motor) })}
      />,
    ]));
  }

  if (filters.rearAxles) {
    chips.push(...filters.rearAxles.map((axles) => [
      `axles ${axles}`,
      <Chip
        label={axles}
        onDelete={() => {
          updateParams({ rearAxles: filters.rearAxles.filter((item) => item !== axles) });
        }}
      />,
    ]));
  }

  if (filters.tractions) {
    chips.push(...filters.tractions.map((traction) => [
      `traction ${traction}`,
      <Chip
        label={traction}
        onDelete={() => {
          updateParams({ tractions: filters.tractions.filter((item) => item !== traction) });
        }}
      />,
    ]));
  }

  if (filters.vehicles) {
    chips.push(...filters.vehicles.map((vehicle) => [
      `vehicle ${vehicle}`,
      <Chip
        label={vehicle}
        onDelete={() => {
          updateParams({ vehicles: filters.vehicles.filter((item) => item !== vehicle) });
        }}
      />,
    ]));
  }

  if (!chips.length) return null;

  return (
    <div className={className}>
      {chips.map(([key, chip]) => (
        <span key={key} className="p-1">
          {chip}
        </span>
      ))}
    </div>
  );
};

VehicleFilterChips.propTypes = {
  className: PropTypes.string,
};

VehicleFilterChips.defaultProps = {
  className: '',
};

export default VehicleFilterChips;
