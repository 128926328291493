import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const ModelFilter = ({ type }) => {
  const { t } = useTranslation('search');
  const { data, prevData, params, updateParams } = useGetFiltersInfo();

  const options = data?.models || prevData?.models || [];

  const onChange = (newModels) => {
    updateParams({
      models: newModels,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="model-filter"
      options={options}
      onChange={onChange}
      placeholder={t('Search')}
      type={type}
      value={params.models}
    />
  );
};

ModelFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ModelFilter;
