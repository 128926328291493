import { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import VehicleListFilters from './VehicleListFilters';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';

const VehicleFiltersModal = () => {
  const { t } = useTranslation('search');
  const { data, loading } = useGetFiltersInfo();
  const [visible, setVisible] = useState(false);

  const toggleModal = () => setVisible(!visible);

  return (
    <>
      <Button startIcon={<FilterListIcon />} onClick={toggleModal}>
        {t('Filters')}
      </Button>
      <Dialog open={visible}>
        <DialogTitle>
          {t('Filters')}
          <IconButton onClick={toggleModal} className="float-right">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <VehicleListFilters />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={toggleModal} disabled={loading}>
            {loading ? t('general:Loading') : `Ver ${data?.total} vehiculos`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VehicleFiltersModal;
