import { useQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import useConfig from '../hooks/useConfig';
import useGetSellers from '../hooks/useGetSellers';
import useSearchFiltersParams from '../hooks/useSearchFiltersParams';
import searchVehicleQuery from '../queries/searchVehiclesQuery';
import VehicleListItem from './VehicleListItem';

export default function VehicleList() {
  const { t } = useTranslation('search');
  const { params, updateParams } = useSearchFiltersParams();
  const { searchResultType } = useConfig();

  const isCompact = searchResultType === 'Compact';

  const sellerQuery = useGetSellers();
  const { data, error, loading } = useQuery(searchVehicleQuery, {
    variables: params,
  });

  const onChangePagination = (e, value) => {
    updateParams({
      page: value,
    });
  };

  if (loading || sellerQuery.loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <div>{t('Search Vehicles', { context: 'error' })}</div>;
  }

  const { items, pages } = data.searchVehicles;

  if (items.length === 0) {
    return (
      <div className="text-center">
        <div className="text-lg mb-2">{t('No Results')}</div>
        <div className="text-4xl">:(</div>
      </div>
    );
  }

  return (
    <>
      {/* <div className="flex flex-wrap w-full -m-2"> */}
      <div
        className={`
          grid
          grid-cols-1
          sm:grid-cols-2
          lg:grid-cols-3
          xl:grid-cols-3
          ${isCompact ? 'gap-2' : 'gap-6'}
          mb-4
        `}
      >
        {items.map((vehicle) => (
          <VehicleListItem key={vehicle.id} vehicle={vehicle} />
        ))}
      </div>
      {pages > 1 && (
        <div className="flex justify-center">
          <Pagination count={pages} page={params.page} onChange={onChangePagination} />
        </div>
      )}
    </>
  );
}
