import PropTypes from 'prop-types';
import CardsSelectorOption from './CardsSelectorOption';

const CardsSelector = ({ options, onChange, value }) => (
  <div className="flex flex-wrap -m-1 pt-1 w-full">
    {options.map((option) => {
      const isSelected = !!value.find((selectedValue) => option.name === selectedValue);

      const onClick = () => {
        if (isSelected) {
          onChange(value.filter((item) => item !== option.name));
        } else {
          onChange([...value, option.name]);
        }
      };

      return (
        <div className="w-2/4 p-1" key={option.name}>
          <CardsSelectorOption
            option={option}
            extra={option.extra}
            isActive={isSelected}
            onClick={onClick}
          />
        </div>
      );
    })}
  </div>
);

CardsSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      extra: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

CardsSelector.defaultProps = {
  onChange: () => {},
  value: [],
};

export default CardsSelector;
