import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';

const ColoredCheckbox = withStyles({
  root: {
    color: ({ color }) => color,
    '&checked': {
      color: ({ color }) => color,
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox {...props} color="default" />);

const CheckboxesSelector = ({ onChange, options, value }) => (
  <div className="flex flex-wrap">
    {options.map(({ name, count, extra }) => {
      const isSelected = value.includes(name);

      const onClick = (e, checked) => {
        if (checked) {
          onChange([...value, name]);
        } else {
          onChange(value.filter((item) => item !== name));
        }
      };

      return (
        <div className="w-2/4" key={name}>
          <FormControlLabel
            label={`${name} (${count})`}
            control={
              <ColoredCheckbox checked={isSelected} onChange={onClick} color={extra || 'default'} />
            }
          />
        </div>
      );
    })}
  </div>
);

CheckboxesSelector.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      extra: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

CheckboxesSelector.defaultProps = {
  onChange: () => {},
  value: [],
};

export default CheckboxesSelector;
