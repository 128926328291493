import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function isValidValue(value) {
  if (!value) return false;

  const [from, to] = value;
  return typeof from === 'number' && typeof to === 'number';
}

function findBegin(value, ranges) {
  const belowRange = value < ranges[0].from;
  if (belowRange) return 0;

  const rangeIndex = ranges.findIndex(({ from, to }) => {
    global.a = value;
    return value >= from && value < to;
  });

  return rangeIndex === -1 ? 0 : rangeIndex;
}

function findEnd(value, ranges) {
  const aboveRange = value > ranges[ranges.length - 1].to;
  if (aboveRange) return ranges.length - 1;

  const rangeIndex = ranges.findIndex(({ from, to }) => {
    global.a = value;
    return value > from && value <= to;
  });

  return rangeIndex === -1 ? 0 : rangeIndex;
}

function valueToSelection(value, ranges) {
  const [from, to] = value;
  const beginIndex = findBegin(from, ranges);
  const endIndex = findEnd(to, ranges);

  return [beginIndex, endIndex + 1];
}

function selectionToValue(selection, ranges) {
  const [from, to] = selection;

  const begin = ranges[from];
  const end = ranges[to - 1];

  return [begin.from, end.to];
}

function getColumHeight(range, ranges) {
  const counters = ranges.map(({ count }) => count);
  // const min = Math.min(...counters);
  const max = Math.max(...counters);
  const height = (range.count / max) * 100;

  return `${height}%`;
}

function isRangeSelected(selection, index) {
  const [begin, end] = selection;
  return index >= begin && index < end;
}

// TODO: this crashes when ranges is an array of 1 element

const HistogramSlider = ({ beginTag, className, endTag, ranges, onChange, value }) => {
  const [selection, setSelection] = useState(
    isValidValue(value) ? valueToSelection(value, ranges) : [0, ranges.length],
    // use this for tests
    // : valueToSelection([ranges[0].from, ranges[ranges.length - 1].to], ranges),
  );

  useEffect(() => {
    if (isValidValue(value)) {
      setSelection(valueToSelection(value, ranges));
    } else {
      setSelection([0, ranges.length]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value && value[0], value && value[1], ranges]);

  const handleOnChange = (e, newValue) => {
    if (newValue[0] === newValue[1]) return;
    setSelection(newValue);

    // const result = selectionToValue(newValue, ranges);
    // onChange(result);
  };

  function handleOnChangeCommitted(e, newValue) {
    const result = selectionToValue(newValue, ranges);
    onChange(result);
  }

  const [begin, end] = selection;

  return (
    <div className={`${className} flex flex-col px-2`}>
      <div className="flex-1 flex items-end relative">
        {ranges.map((range, index) => (
          <div
            key={index}
            className={`
              flex-1
              bg-primary
              transition-all
              border-r
              ${isRangeSelected(selection, index) ? 'opacity-30' : 'opacity-10'}
            `}
            style={{
              height: getColumHeight(range, ranges),
            }}
          />
        ))}
      </div>
      <Slider
        min={0}
        max={ranges.length}
        step={1}
        value={selection}
        onChange={handleOnChange}
        onChangeCommitted={handleOnChangeCommitted}
      />
      <div className="flex justify-between">
        <span>{beginTag ? beginTag(ranges[begin].from) : ranges[begin].from}</span>
        <span>{endTag ? endTag(ranges[Math.min(end, ranges.length) - 1].to) : ranges[Math.min(end, ranges.length) - 1].to}</span>
      </div>
    </div>
  );
};

HistogramSlider.propTypes = {
  beginTag: PropTypes.func,
  className: PropTypes.string,
  endTag: PropTypes.func,
  onChange: PropTypes.func,
  // onChangeCommitted: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.number),
};

HistogramSlider.defaultProps = {
  beginTag: null,
  className: '',
  endTag: null,
  onChange: () => {},
  // onChangeCommitted: () => {},
  // sine curve
  ranges: new Array(10)
    .fill(Math.PI)
    .map((PI, index) => (PI / 9) * index)
    .map((value) => Math.sin(value) * 10)
    .map((value) => Math.floor(value) + 1)
    .map((value, index) => ({
      count: value,
      from: index,
      to: index + 1,
    })),
  value: null,
};

export default HistogramSlider;
