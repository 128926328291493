import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const TruckClassFilter = ({ type }) => {
  const {
    data, prevData, params, updateParams,
  } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.truckClasses || prevData?.truckClasses || [];

  const onChange = (value) => {
    updateParams({
      classes: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="truck-class-filter"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.classes}
    />
  );
};

TruckClassFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TruckClassFilter;
