import { gql } from '@apollo/client';

const searchVehicleQuery = gql`
  query (
    $bodyTypes: [String!]
    $brands: [String!]
    $colors: [String!]
    $locations: [String!]
    $maxKms: Int
    $maxPrice: Int
    $minKms: Int
    $minPrice: Int
    $models: [String!]
    $page: Int
    $search: String
    $sellers: [String!]
    $sort: SortEnum
    $states: [String!]
    $transmissions: [String!]
    $years: [String!]
    $maxEngineUsage: Int
    $minEngineUsage: Int
    $vehicles: [String!]
    $fifthWheels: [String!]
    $rearAxles: [String!]
    $cabs: [String!]
    $tractions: [String!]
    $motors: [String!]
    $classes: [String!]
    $fuels: [String!]
  ) {
    searchVehicles(
      input: {
        bodyTypes: $bodyTypes
        brands: $brands
        colors: $colors
        locations: $locations
        maxKms: $maxKms
        maxPrice: $maxPrice
        minKms: $minKms
        minPrice: $minPrice
        models: $models
        page: $page
        sellers: $sellers
        search: $search
        sort: $sort
        states: $states
        transmissions: $transmissions
        years: $years
        maxEngineUsage: $maxEngineUsage
        minEngineUsage: $minEngineUsage
        vehicleTypes: $vehicles
        fifthWheels: $fifthWheels
        rearAxlesCapacities: $rearAxles
        cabs: $cabs
        tractionControls: $tractions
        motors: $motors
        truckClasses: $classes
        fuelTypes: $fuels
      }
    ) {
      pages
      items {
        currency
        description
        id
        ref
        kms
        location
        picture
        price
        seller
        year
        certified
        state
        exteriorColor
        interiorColor
        licensePlate
        brand
        model
        year
        trim
        transmission
        doors
        fuelType
        steering
        tractionControl
        vehicleBodyType
        engine
        customValues {
          name
          value
        }
      }
    }
  }
`;

export default searchVehicleQuery;
