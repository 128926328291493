import Color from 'color';
import PropTypes from 'prop-types';

function parseColor(extra) {
  if (!extra || !extra.startsWith('#')) return new Color('#888');

  return new Color(extra);
}

function checkIfIsImg(extra) {
  if (!extra || extra.startsWith('#')) return false;
  return true;
}

const CardsSelectorOption = ({ isActive, onClick, option }) => {
  const color = parseColor(option.extra);
  const isImg = checkIfIsImg(option.extra);
  return (
    <button
      className="
        py-1 px-2
        text-sm
        text-center
        truncate
        border
        focus:outline-none
        rounded-xl
        w-full
      "
      onClick={onClick}
      style={{
        borderColor: color.toString(),
        backgroundColor: isActive ? color.fade(0.7).toString() : 'transparent',
      }}
      type="button"
    >
      {isImg && (
        <div className="flex justify-center">
          <div className="relative w-12 h-10 flex flex-column">
            <img
              src={option.extra}
              alt={option.name}
              className="object-contain object-center max-w-full max-h-full"
            />
          </div>
        </div>
      )}
      <div className="flex">
        <span className="truncate">{option.name}</span>
        <span className="ml-1">{`(${option.count})`}</span>
      </div>
    </button>
  );
};

CardsSelectorOption.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    extra: PropTypes.string,
  }).isRequired,
};

CardsSelectorOption.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default CardsSelectorOption;
