import PropTypes from 'prop-types';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import RangeSelector from '../ui/RangeSelector';
import formatNumber from '../utils/formatNumber';
import getLimits from '../utils/getLimits';

const PriceFilter = ({ type }) => {
  const { loading, params, prevData, data, updateParams } = useGetFiltersInfo();

  const onChange = ([min, max]) => {
    const limits = getLimits(data.priceRanges);
    const allSelected = min === limits.min && max === limits.max;

    updateParams({
      minPrice: allSelected ? null : min,
      maxPrice: allSelected ? null : max,
      page: 1,
    });
  };

  return (
    <RangeSelector
      beginTag={(value) => `$${formatNumber(value)}`}
      endTag={(value) => `$${formatNumber(value)}`}
      onChange={onChange}
      ranges={data?.priceRanges || prevData?.priceRanges}
      type={type}
      value={loading ? null : [params.minPrice, params.maxPrice]}
      className="h-32"
    />
  );
};

PriceFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PriceFilter;
