import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const MotorFilter = ({ type }) => {
  const {
    data, prevData, params, updateParams,
  } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.motors || prevData?.motors || [];

  const onChange = (value) => {
    updateParams({
      motors: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="motor-filter"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.motors}
    />
  );
};

MotorFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default MotorFilter;
