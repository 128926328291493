import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const YearFilter = ({ type }) => {
  const { data, prevData, params, updateParams } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.years || prevData?.years || [];

  const onChange = (value) => {
    updateParams({
      years: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="year-filter"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.years}
    />
  );
};

YearFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default YearFilter;
