import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const BrandsFilter = ({ type }) => {
  const { t } = useTranslation('search');
  const { data, prevData, params, updateParams } = useGetFiltersInfo();

  const options = data?.brands || prevData?.brands || [];

  const onChange = (newBrands) => {
    updateParams({
      brands: newBrands,
      models: [],
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="brand-selector"
      options={options}
      onChange={onChange}
      placeholder={t('Search')}
      type={type}
      value={params.brands}
    />
  );
};

BrandsFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BrandsFilter;
