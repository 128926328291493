import { Card, Hidden } from '@material-ui/core';
import RemoveFiltersButton from '../../components/RemoveFiltersButton';
import VehicleFilterChips from '../../components/VehicleFilterChips';
import VehicleFiltersModal from '../../components/VehicleFiltersButton';
import VehicleList from '../../components/VehicleList';
import VehicleListFilters from '../../components/VehicleListFilters';
import VehicleListSorts from '../../components/VehicleListSorts';
import SearchLayout from '../../layouts/SearchLayout';

const SearchPage = () => (
  <SearchLayout>
    {/* <div className="md:flex items-start"> */}
    <div className="md:flex md:items-start w-full">
      <Hidden smDown>
        {/* <div className="md:w-60 md:mx-4 md:mt-12 top-0 sticky"> */}
        <Card className="w-72 mx-2 p-4">
          <VehicleListFilters />
        </Card>
      </Hidden>
      <div className="md:w-auto flex-1 md:mx-4">
        <div className="mb-4">
          <div className="flex items-end mb-2">
            <Hidden mdUp>
              <div>
                <VehicleFiltersModal />
                <RemoveFiltersButton iconVariant />
              </div>
            </Hidden>
            <Hidden smDown>
              <RemoveFiltersButton />
            </Hidden>
            <div className="ml-auto">
              <VehicleListSorts />
            </div>
          </div>
          <div className="-m-1">
            <VehicleFilterChips className="pb-1" />
          </div>
        </div>
        <VehicleList />
      </div>
    </div>
  </SearchLayout>
);

export default SearchPage;
