/* eslint-disable jsx-a11y/anchor-is-valid */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import urlify from '../utils/urlify';
import formatNumber from '../utils/formatNumber';
import CompactCardItem from '../ui/CompactCardItem';
import useConfig from '../hooks/useConfig';
import NormalCardItem from '../ui/NormalCardItem';
import useGetSellers from '../hooks/useGetSellers';

function ParsedText({text, vehicle}) {
  const elems = useMemo(() => {
    const parts = text.slice().split(/{{[^{}]*}}/g);
    // insert empty parts
    for (let i = 2; i < parts.length; i++) {
      if (parts[i] !== '') parts.splice(i++, 0, '');
    }

    const params = text.match(/{{[^{}]*}}/g);

    let i = -1, l = 0;
    for (const param of params) {

      // parse current param to element or value
      const paramElement = (() => {
        const parsedParam = param.slice(2,-2);
        if ( /"[^{}]*"/g.test(parsedParam)) {
          const customValueName = parsedParam.slice(1,-1);
          return vehicle.customValues?.find(value => value.name === customValueName)?.value;
      // check if the parameter is fixed, otherwise do nothing
        } else {
          if (parsedParam === 'price') {
            return (
              <>
                {`$${formatNumber(vehicle.price)} `}
                <span className="text-sm">{vehicle.currency}</span>
              </>
            );
          }
          else if (parsedParam === 'kms') {
            return `${formatNumber(vehicle.kms)} kms`;
          } else return vehicle[parsedParam];
        }
      })();

      // get empty part index
      i = parts.slice(l).indexOf("") + l;
      parts[i] = paramElement;
      l = i + 1;
    }
    
    return parts;
  }, [text, vehicle])

  return (
    <>
      {elems.map(elem => elem)}
    </>
  );
}

ParsedText.propTypes = {
  text: PropTypes.string.isRequired,
  vehicle: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    kms: PropTypes.number.isRequired,
    location: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    seller: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    ref: PropTypes.string,
  }).isRequired,
};


export default function VehicleListItem({ vehicle }) {
  const {
    copies,
    hideSellerInfo,
    searchResultType,
    listedCertificationBadge,
    descriptionCertificationBadge,
    listPrimaryText,
    listSecondaryText,
  } = useConfig();
  const { sellers } = useGetSellers();
  const showSeller = !hideSellerInfo && sellers && sellers.length > 1;
  const certificationBadge = listedCertificationBadge || descriptionCertificationBadge;

  return (
    <Link href={`/auto/${urlify(vehicle.description)}/${vehicle.id}`}>
      <a className="vehicleListItem relative">
        {certificationBadge && vehicle.certified && (
          <img
            alt="certified vehicle"
            src={certificationBadge}
            className="absolute -top-5 -right-5 h-24 object-cover"
          />
        )}
        {searchResultType === 'Compact' && (
          <CompactCardItem backgroundImg={vehicle.picture}>
            <p className="pricing text-2xl">
              {`$${formatNumber(vehicle.price)} `}
              <span className="text-sm">{vehicle.currency}</span>
            </p>
            <p className="description">
              <ParsedText text={listSecondaryText} vehicle={vehicle} />
            </p>
            <p className="info">{vehicle.description.trim().replace(/,+$/, '')}</p>
          </CompactCardItem>
        )}
        {searchResultType === 'Normal' && (
          <NormalCardItem backgroundImg={vehicle.picture} hasSeller={showSeller}>
            <p className="pricing text-2xl font-medium text-previewPricing mb-1">
              {`$${formatNumber(vehicle.price)} `}
              <span className="text-sm">{vehicle.currency}</span>
            </p>
            <p className="description">
              <ParsedText text={listPrimaryText} vehicle={vehicle} />
            </p>
            <p className="info text-sm text-gray-500">
              <ParsedText text={listSecondaryText} vehicle={vehicle} />
            </p>
            {showSeller && (
              <p className="text-sm text-gray-500">{`${copies.soldBy} ${vehicle.seller}`}</p>
            )}
          </NormalCardItem>
        )}
      </a>
    </Link>
  );
}

VehicleListItem.propTypes = {
  vehicle: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    kms: PropTypes.number.isRequired,
    location: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    seller: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    ref: PropTypes.string,
  }).isRequired,
};
