import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Color from 'color';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function parseColor(extra) {
  if (!extra || !extra.startsWith('#')) return new Color('#888');

  return new Color(extra);
}

function checkIfIsImg(extra) {
  if (!extra || extra.startsWith('#')) return false;
  return true;
}

const AutocompleteSelector = ({ id, onChange, placeholder, value, options }) => {
  const selectedOptions = value
    .map((modelName) => options.find((option) => option.name === modelName))
    .filter(Boolean);

  const handleOnChange = (e, selectedItems) => {
    const newValue = selectedItems.map((item) => item.name);
    onChange(newValue);
  };

  return (
    <Autocomplete
      id={id}
      value={selectedOptions}
      options={options}
      multiple
      disableCloseOnSelect
      onChange={handleOnChange}
      getOptionLabel={(el) => `${el.name} (${el.count})`}
      renderOption={(option, { selected }) => {
        const isImg = checkIfIsImg(option.extra);
        const color = isImg ? null : parseColor(option.extra).toString();

        return (
          <>
            {isImg && (
              <div className="relative w-12 h-10 flex flex-column mr-4">
                <img
                  src={option.extra}
                  alt={option.name}
                  className="object-contain object-center max-w-full max-h-full"
                />
              </div>
            )}
            {!isImg && (
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" style={{ color }} />}
                checkedIcon={<CheckBoxIcon fontSize="small" style={{ color }} />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {`${option.name} (${option.count})`}
          </>
        );
      }}
      renderInput={(inputProps) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          variant="outlined"
          placeholder={placeholder}
        />
      )}
    />
  );
};

AutocompleteSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      extra: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AutocompleteSelector.defaultProps = {
  placeholder: '',
};

export default AutocompleteSelector;
