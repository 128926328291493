import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import useConfig from '../hooks/useConfig';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import DynamicVehicleFilter from './DynamicVehicleFilter';

const useStyles = makeStyles({
  blur: {
    filter: 'blur(5px)',
    pointerEvents: 'none',
  },
});

const VehicleListFilters = () => {
  const { t } = useTranslation('search');
  const styles = useStyles();
  const { searchFilters } = useConfig();
  const { error, loading } = useGetFiltersInfo();

  if (error) return 'error';

  return (
    <div id="filtersContainer" className={`${loading ? styles.blur : ''} -md:max-w-xl -md:mx-auto`}>
      {searchFilters.map(
        ({ enabled, filter, type }) =>
          enabled && (
            <div className="mb-4" key={filter}>
              {t('Filter Type', { context: filter.toLowerCase() })}
              <DynamicVehicleFilter filter={filter} type={type} />
            </div>
          ),
      )}
    </div>
  );
};

export default VehicleListFilters;
