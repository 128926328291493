import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import onEnter from '../utils/onEnter';

const SliderRangeInput = ({ max, min, onChange, value }) => {
  const [activeValue, setActiveValue] = useState(value);

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  const handleOnChange = ({ target }) => {
    setActiveValue(target.value);
  };

  const checkForUpdateValue = () => {
    if (activeValue < min || activeValue > max) {
      // out of range, reset value
      setActiveValue(value);
    } else if (value !== activeValue) {
      onChange(Number(activeValue));
    }
  };

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      min={min}
      max={max}
      onChange={handleOnChange}
      onBlur={checkForUpdateValue}
      onKeyPress={onEnter(checkForUpdateValue)}
      size="small"
      type="number"
      variant="outlined"
      value={activeValue}
    />
  );
};

SliderRangeInput.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default SliderRangeInput;
