import PropTypes from 'prop-types';
import HistogramSlider from './HistogramSlider';
import SliderRange from './SliderRange';

const RangeSelector = ({ beginTag, className, endTag, ranges, onChange, value, type }) => {
  if (type === 'histogram') {
    return (
      <HistogramSlider
        beginTag={beginTag}
        endTag={endTag}
        onChange={onChange}
        ranges={ranges}
        value={value}
        className={className}
      />
    );
  }

  if (type === 'slider') {
    return <SliderRange onChange={onChange} ranges={ranges} value={value} />;
  }

  return null;
};

RangeSelector.propTypes = {
  beginTag: PropTypes.func,
  className: PropTypes.string,
  endTag: PropTypes.func,
  onChange: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ),
  type: PropTypes.oneOf(['histogram', 'slider']).isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

RangeSelector.defaultProps = {
  beginTag: null,
  className: null,
  endTag: null,
  onChange: () => {},
  ranges: undefined,
  value: null,
};

export default RangeSelector;
