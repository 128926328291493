import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import SliderRangeInput from './SliderRangeInput';

function valueIsValid(value) {
  if (!value) return false;
  return value.every((el) => el !== null);
}

const SliderRange = ({ onChange, ranges, value }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleOnChange = (e, newValue) => {
    setSelectedValue(newValue);
  };

  const handleOnChangeCommitted = (e, newValue) => {
    onChange(newValue);
  };

  const range = ranges[0] || { from: 0, to: 100 };
  const fixedValue = valueIsValid(selectedValue) ? selectedValue : [range.from, range.to];

  const onChangeBegin = (newBegin) => {
    onChange([newBegin, fixedValue[1]]);
  };
  const onChangeEnd = (newEnd) => {
    onChange([fixedValue[0], newEnd]);
  };

  return (
    <div>
      <Slider
        min={range.from}
        max={range.to}
        onChange={handleOnChange}
        onChangeCommitted={handleOnChangeCommitted}
        value={fixedValue}
      />
      <div className="flex justify-between">
        <SliderRangeInput
          min={range.from}
          max={Math.min(range.to, fixedValue[1])}
          onChange={onChangeBegin}
          value={fixedValue[0]}
        />
        <SliderRangeInput
          min={Math.max(range.from, fixedValue[0])}
          max={range.to}
          onChange={onChangeEnd}
          value={fixedValue[1]}
        />
      </div>
    </div>
  );
};

SliderRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.number),
};

SliderRange.defaultProps = {
  ranges: [],
  value: null,
};

export default SliderRange;
