import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const BodyTypeFilter = ({ type }) => {
  const { t } = useTranslation('search');
  const { data, prevData, params, updateParams } = useGetFiltersInfo();

  const options = data?.bodyTypes || prevData?.bodyTypes || [];

  const onChange = (newBodyTypes) => {
    updateParams({
      bodyTypes: newBodyTypes,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="body-type-filter"
      options={options}
      onChange={onChange}
      placeholder={t('Search')}
      type={type}
      value={params.bodyTypes}
    />
  );
};

BodyTypeFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BodyTypeFilter;
