import { Card, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  card: {
    height: ({ hasSeller }) => (hasSeller ? '25rem' : '24rem'),
    '&:hover': {
      boxShadow:
        '0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%) !important',
    },
  },
});

const NormalCardItem = ({ backgroundImg, backgroundImgAlt, hasSeller, children }) => {
  const styles = useStyles({ hasSeller });
  return (
    <Card className={`cursor-pointer ${styles.card}`}>
      <img className="block w-full h-64 object-cover" src={backgroundImg} alt={backgroundImgAlt} />
      <div className="p-3 px-4">{children}</div>
    </Card>
  );
};

NormalCardItem.propTypes = {
  backgroundImg: PropTypes.string,
  backgroundImgAlt: PropTypes.string,
  hasSeller: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

NormalCardItem.defaultProps = {
  backgroundImg: '',
  backgroundImgAlt: '',
  hasSeller: false,
};

export default NormalCardItem;
