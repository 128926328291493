import { gql } from '@apollo/client';

const getFiltersInfoQuery = gql`
  query (
    $bodyTypes: [String!]
    $brands: [String!]
    $colors: [String!]
    $locations: [String!]
    $maxKms: Int
    $maxPrice: Int
    $minKms: Int
    $minPrice: Int
    $models: [String!]
    $search: String
    $sellers: [String!]
    $states: [String!]
    $transmissions: [String!]
    $years: [String!]
    $maxEngineUsage: Int
    $minEngineUsage: Int
    $vehicles: [String!]
    $fifthWheels: [String!]
    $rearAxles: [String!]
    $cabs: [String!]
    $tractions: [String!]
    $motors: [String!]
    $classes: [String!]
    $fuels: [String!]
  ) {
    getFiltersInfo(input: {
      bodyTypes: $bodyTypes
      brands: $brands
      colors: $colors
      locations: $locations
      maxKms: $maxKms
      maxPrice: $maxPrice
      minKms: $minKms
      minPrice: $minPrice
      models: $models
      search: $search
      sellers: $sellers
      states: $states
      transmissions: $transmissions
      years: $years
      maxEngineUsage: $maxEngineUsage
      minEngineUsage: $minEngineUsage
      vehicleTypes: $vehicles
      fifthWheels: $fifthWheels
      rearAxlesCapacities: $rearAxles
      cabs: $cabs
      tractionControls: $tractions
      motors: $motors
      truckClasses: $classes
      fuelTypes: $fuels
    }) {
      bodyTypes {
        name
        count
        extra
      }
      brands {
        name
        count
      }
      colors {
        name
        count
        extra
      }
      kmsRanges {
        from
        to
        count
      }
      locations {
        name
        count
      }
      models {
        name
        count
      }
      priceRanges {
        from
        to
        count
      }
      sellers {
        name
        count
      }
      states {
        name
        count
      }
      transmissions {
        name
        count
      }
      years {
        name
        count
      }
      vehicleTypes {
        name
        count
      }
      fifthWheels {
        name
        count
      }
      rearAxlesCapacities {
        name
        count
      }
      cabs {
        name
        count
      }
      tractionControls {
        name
        count
      }
      motors {
        name
        count
      }
      engineUsageRanges {
        from
        to
        count
      }
      truckClasses {
        name
        count
      }
      fuelTypes {
        name
        count
      }
      total
    }
  }
`;

export default getFiltersInfoQuery;
