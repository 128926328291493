import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import useSearchFiltersParams from '../hooks/useSearchFiltersParams';

const RemoveFiltersButton = ({ iconVariant }) => {
  const { t } = useTranslation('search');
  const { removeParams, selectedFilters } = useSearchFiltersParams();

  const selectedFiltersCount = Object.keys(selectedFilters).length;

  if (!selectedFiltersCount) return null;

  if (iconVariant) {
    return (
      <IconButton className="p-0" onClick={removeParams}>
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <Button onClick={removeParams} variant="outlined" endIcon={<CloseIcon />}>
      {t('Remove Filters')}
    </Button>
  );
};

RemoveFiltersButton.propTypes = {
  iconVariant: PropTypes.bool,
};

RemoveFiltersButton.defaultProps = {
  iconVariant: false,
};

export default RemoveFiltersButton;
