import PropTypes from 'prop-types';
import BodyTypeFilter from './BodyTypeFilter';
import BrandsFilter from './BrandsFilter';
import CabsFilter from './CabsFilter';
import ColorFilter from './ColorFilter';
import EngineUsageFilter from './EngineUsageFilter';
import FifthWheelFilter from './FifthWheelFilter';
import FuelTypeFilter from './FuelTypeFilter';
import KmsFilter from './KmsFilter';
import LocationFilter from './LocationFilter';
import ModelFilter from './ModelFilter';
import MotorFilter from './MotorFilter';
import PriceFilter from './PriceFilter';
import RearAxlesCapacityFilter from './RearAxlesCapacityFilter';
import TractionControlFilter from './TractionControlFilter';
import SellerFilter from './SellerFilter';
import TransmissionFilter from './TransmissionFilter';
import TruckClassFilter from './TruckClassFilter';
import VehicleStateFilter from './VehicleStateFilter';
import VehicleTypeFilter from './VehicleTypeFilter';
import YearFilter from './YearFilter';

const DynamicVehicleFilter = ({ filter, type: _type }) => {
  const type = _type.toLowerCase();

  switch (filter) {
    case 'BodyType':
      return <BodyTypeFilter type={type} />;
    case 'Brand':
      return <BrandsFilter type={type} />;
    case 'Color':
      return <ColorFilter type={type} />;
    case 'Kms':
      return <KmsFilter type={type} />;
    case 'Location':
      return <LocationFilter type={type} />;
    case 'Model':
      return <ModelFilter type={type} />;
    case 'Price':
      return <PriceFilter type={type} />;
    case 'Seller':
      return <SellerFilter type={type} />;
    case 'State':
      return <VehicleStateFilter type={type} />;
    case 'Transmission':
      return <TransmissionFilter type={type} />;
    case 'Year':
      return <YearFilter type={type} />;
    case 'VehicleType':
      return <VehicleTypeFilter type={type} />;
    case 'FifthWheel':
      return <FifthWheelFilter type={type} />;
    case 'RearAxlesCapacity':
      return <RearAxlesCapacityFilter type={type} />;
    case 'Cab':
      return <CabsFilter type={type} />;
    case 'TractionControl':
      return <TractionControlFilter type={type} />;
    case 'Motor':
      return <MotorFilter type={type} />;
    case 'EngineUsage':
      return <EngineUsageFilter type={type} />;
    case 'TruckClass':
      return <TruckClassFilter type={type} />;
    case 'FuelType':
      return <FuelTypeFilter type={type} />;
    default:
  }

  return null;
};

DynamicVehicleFilter.propTypes = {
  filter: PropTypes.oneOf([
    'BodyType',
    'Brand',
    'Color',
    'Kms',
    'Location',
    'Model',
    'Price',
    'Seller',
    'State',
    'Transmission',
    'Year',
    'VehicleType',
    'FifthWheel',
    'RearAxlesCapacity',
    'Cab',
    'TractionControl',
    'Motor',
    'EngineUsage',
    'TruckClass',
    'FuelType',
  ]).isRequired,
  type: PropTypes.string.isRequired,
};

export default DynamicVehicleFilter;
