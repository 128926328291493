import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel } from '@material-ui/core';
import useSearchFiltersParams from '../hooks/useSearchFiltersParams';

const VehicleListSorts = () => {
  const { params, updateParams } = useSearchFiltersParams();

  const onChange = ({ target }) => {
    updateParams({
      page: 1,
      sort: target.value,
    });
  };

  return (
    <div className="flex justify-end">
      <FormControl>
        <InputLabel id="sort-select-label" htmlFor="sort-select">Ordenar por</InputLabel>
        <Select
          id="sort-select"
          labelId="sort-select-label"
          value={params.sort || ''}
          onChange={onChange}
        >
          <MenuItem value="Default">Más relevante</MenuItem>
          <MenuItem value="AscPrice">Menor precio</MenuItem>
          <MenuItem value="DesPrice">Mayor precio</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default VehicleListSorts;
