import PropTypes from 'prop-types';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import RangeSelector from '../ui/RangeSelector';
import formatNumber from '../utils/formatNumber';
import getLimits from '../utils/getLimits';

const EngineUsageFilter = ({ type }) => {
  const {
    loading, params, prevData, data, updateParams,
  } = useGetFiltersInfo();

  const onChange = ([min, max]) => {
    const limits = getLimits(data.engineUsageRanges);
    const allSelected = min === limits.min && max === limits.max;

    updateParams({
      minEngineUsage: allSelected ? null : min,
      maxEngineUsage: allSelected ? null : max,
      page: 1,
    });
  };

  return (
    <RangeSelector
      beginTag={(value) => `${formatNumber(value)}hrs`}
      endTag={(value) => `${formatNumber(value)}hrs`}
      onChange={onChange}
      ranges={data?.engineUsageRanges || prevData?.engineUsageRanges}
      type={type}
      value={loading ? null : [params.minEngineUsage, params.maxEngineUsage]}
      className="h-32"
    />
  );
};

EngineUsageFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EngineUsageFilter;
