import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useGetFiltersInfo from '../hooks/useGetFiltersInfo';
import OptionsSelector from '../ui/OptionsSelector';

const ColorFilter = ({ type }) => {
  const { data, prevData, params, updateParams } = useGetFiltersInfo();
  const { t } = useTranslation('search');

  const options = data?.colors || prevData?.colors || [];

  const onChange = (value) => {
    updateParams({
      colors: value,
      page: 1,
    });
  };

  return (
    <OptionsSelector
      id="color-selector"
      onChange={onChange}
      options={options}
      placeholder={t('Search')}
      type={type}
      value={params.colors}
    />
  );
};

ColorFilter.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ColorFilter;
